import Vue from 'vue'
import App from './page/App.vue'
import veform from '@verified/veform'
import { validateDob} from './validations'
import { validateZipSE} from './validations'
import Stepper from './components/stepper'
import Checkbox from './components/checkbox'
import CustomRadio from './components/CustomRadio'
import Multiselect from 'vue-multiselect'
import Datepicker from 'vuejs-datepicker';

Vue.config.productionTip = false

import "./page/style.scss"

veform.addCustomValidation('dob', validateDob)
veform.addCustomValidation('zipse', validateZipSE)

Vue.use(veform)
Vue.use(Stepper)
Vue.use(Checkbox)
Vue.use(CustomRadio)
Vue.component('multiselect', Multiselect)
Vue.component('Datepicker',Datepicker)
new Vue({
    render: h => h(App),
}).$mount('#app')