<template>
<div ref="parent">
	
	<div v-for="(item, id) in options"
	     :key="id"
	     :class="['custom-control', { 'custom-control-inline': inline }, 'custom-radio', 'mb-1']">
		
		<input type="radio"
		       class="custom-control-input"
			   :name="group || _uid"
		       :value="value"
		       :checked="value[id]"
			   @change="handleInput"
		       :id="'ve-radio-' + _uid + '-' + id"
		       :disabled="isDisabled">
		
		<label class="custom-control-label custom-radio-label"
		       :for="'ve-radio-' + _uid + '-' + id">{{ item.label }}
			
			<button v-if="item.tip"
			        type="button"
			        data-toggle="tooltip"
			        :class="item.tip['tip-position']=='left' ? 'tooltip-left' : 'tooltip-right'"
			        :data-offset="item.tip.offset||0"
			        :data-placement="item.tip['text-placement']||'top'"
			        :data-trigger="item.tip.trigger||'hover focus'"
			        :data-html="item.tip.html ? 'true' : 'false'"
			        :title="__(item.tip.text||item.tip.html)||item.tip.text||item.tip.html"></button>
		</label>
	</div>
	
	<div v-show="validation_error"
	     class="custom-invalid-feedback">
	</div>

</div>
</template>

<script>

let groups = {}

export default {
	name: 've-custom-radio',
	
	props: {
		value: {
			type: Object,
			default: () => { return {} }
		},
		options: {
			type: Object,
			default: {}
		},
		group: {
			type: String,
			default: ''
		},
		rules: {
			type: String,
			default: ''
		},
		inline: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	
	data() {
		return {
			validation_error: false,
      isDisabled: false
		}
	},
	
	watch: {
		value: {
			deep: true,
			handler(val) {
				this.performValidation(val)
			}
		},
    disabled: function(newVal) {
      this.isDisabled = newVal
		}
	},
	
	methods: {
		
		handleInput(e) {
			let checkedElement = this.$refs.parent.querySelector('input:checked')
			let id = checkedElement ? checkedElement.id.split('ve-radio-' + this._uid + '-')[1] : ''

			const myGroups = groups[this.group]
			if(e && myGroups) {
				const self = this
				myGroups.forEach((g)=>{
					if (g != self)
						g.handleInput()
				})
			}

			for(let key in this.value) {
				const val = key === id
				if(this.value[key] != val)
					this.value[key] = val
			}		
			
			this.$emit('input', this.value)
			this.$emit('radio-click', true)
			
			this.performValidation()
			this.$forceUpdate()
		},
		
		performValidation() {
			if(this.rules.includes('required')) {
				
				let ok = false
				
				for(let key in this.value)
					if(this.value[key]) ok = true
				
				for(let key in this.value) {
					let selected_radio = document.getElementById(`ve-radio-${ this._uid }-${ key }`)
					if(!ok) {
						this.validation_error = true
						selected_radio && selected_radio.setCustomValidity('Select one of the radiobuttons')
					} else {
						this.validation_error = false
						selected_radio && selected_radio.setCustomValidity('')
					}
				}
			}
		}
	},
	
	created() {

		for(let option in this.options)
			if(!this.value[option])
				this.value[option] = false
		this.$emit('input', this.value)
		
		this.$nextTick(() => {
			this.performValidation()
		})
		if(this.group) {
			if(!groups[this.group])
				groups[this.group] = []
			groups[this.group].push(this)
		}
	},

	destroyed() {
		if(this.group) {
			let myGroups = groups[this.group]
			for(let i = 0; i < myGroups.length; i++) {
				let item = myGroups[i]
				if(item === this) {
					myGroups.splice(i,1)
					break
				}
			}
		}
	},
	
	mounted() {
		for(let option in this.options) {
			if(this.options[option].tip) {
				/**
				 * Removing sanitize is a workaround for it to be compatible with IE.
				 * Source: https://stackoverflow.com/questions/56462877/popover-angular-bootstrap-4-3-failure
				 */
				$('[data-toggle="tooltip"]').tooltip({
					sanitize: false, sanitizeFn: content => content
				})
			}
		}
	}
}
</script>

<style lang="scss"
       scoped>

</style>
