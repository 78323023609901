export const validateDob = (value, constraint) => {
    if (/^(19|20)[0-9]{2}-(1[0-2]|0[1-9])-(3[01]|[12][0-9]|0[1-9])$/.test(value)) {
        return { status: true, key: "validation.dob" }
    }
    else{
        return { status: false, key: "validation.dob" }
    }
}

export const validateZipSE = (value, constraint) => {
    if (/^[0-9]{3} [0-9]{2}$/.test(value)) {
        return { status: true, key: "validation.zipse" }
    }
    else{
        return { status: false, key: "validation.zipse" }
    }
}
